import classNames from 'classnames';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';

import { GET_STARTED_SCHEMA } from 'schema/membership/get-started.schema';

import { GetStartedFormValues } from 'types/membership';

import { useGlobalLink } from 'hooks/useGlobalLink';

import './membership-registration-form.style.scss';

export interface RegistrationFormProps {
    initialValues: Partial<GetStartedFormValues>;
    className?: string;
    isBusy: boolean;
    errors?: {
        email?: boolean;
        zipcode?: boolean;
    };
    onSubmit: (values: Partial<GetStartedFormValues>, helpers: FormikHelpers<Partial<GetStartedFormValues>>) => void;
}

const MembershipRegistrationForm = ({
    className,
    initialValues,
    onSubmit,
    isBusy,
    errors: formErrors
}: RegistrationFormProps) => {
    const { t } = useTranslation();

    const classes = classNames('membership-registration-form', className);
    const formName = 'Registration - Info';
    const globalLink = useGlobalLink();

    useEffect(() => {
        globalLink.setFormName(formName);
        globalLink.setFlowName('MembershipGetStarted');
        globalLink.setStepName(t('membership.getStarted.profileTitle'));
    }, [globalLink, t]);

    return (
        <div className={classes}>
            <Row>
                <Col>
                    <Formik onSubmit={onSubmit} validationSchema={GET_STARTED_SCHEMA} initialValues={initialValues}>
                        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldTouched }) => (
                            <Form
                                id="registration-form"
                                data-ga-form-name={formName}
                                onSubmit={handleSubmit}
                                autoComplete="off"
                                className="membership-registration-first-step-form"
                            >
                                <div>
                                    <Row>
                                        <Col>
                                            <Text
                                                name="email"
                                                label={t('membership.getStarted.form.email')}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={(e) => {
                                                    setFieldTouched('email');
                                                    handleBlur(e);
                                                }}
                                                errors={
                                                    errors?.email === 'invalid'
                                                        ? t(`membership.getStarted.form.errors.email.invalid`)
                                                        : errors?.email === 'required'
                                                        ? t(`membership.getStarted.form.errors.email.required`)
                                                        : undefined
                                                }
                                                touched={touched.email}
                                                defaultValue={values?.email}
                                                value={values?.email}
                                                onFocus={() =>
                                                    globalLink.handleFieldFocus(t('membership.getStarted.form.email'))
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Text
                                                name="zipcode"
                                                label={t('membership.getStarted.form.zipCode')}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                onBlur={(e) => {
                                                    setFieldTouched('zipcode');
                                                    handleBlur(e);
                                                }}
                                                maxLength={5}
                                                errors={
                                                    formErrors?.zipcode ||
                                                    errors?.zipcode === 'min' ||
                                                    errors?.zipcode === 'onlyNumbers'
                                                        ? t(`membership.getStarted.form.errors.zipCode.invalid`)
                                                        : errors?.zipcode === 'required'
                                                        ? t(`membership.getStarted.form.errors.zipCode.required`)
                                                        : undefined
                                                }
                                                touched={touched.zipcode}
                                                defaultValue={values?.zipcode}
                                                value={values?.zipcode}
                                                onFocus={() =>
                                                    globalLink.handleFieldFocus(t('membership.getStarted.form.zipCode'))
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center mt-2 mt-md-0">
                                        <Button
                                            async
                                            className="sm-full membership-registration-first-step-form-submit"
                                            disabled={isBusy}
                                            label={t('membership.getStarted.form.buttons.submit')}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="Step1"
                                            dataGAFormStepName="Account"
                                            isBusy={isBusy}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </div>
    );
};

export default MembershipRegistrationForm;
