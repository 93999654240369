import { useDispatch, useSelector } from 'react-redux';
import { membershipRegistrationGetEmailZipcodeRoutine } from 'state/membership-registration/membership-registration.routines';
import { membershipRegistrationSelector } from 'state/membership-registration/membership-registration.selectors';
import { MembershipEmailZipcodePayload } from 'state/membership-registration/membership-registration.services';

export interface VerifyEmailZipcodeProps {
    email: string;
    zipcode: string;
    onSuccess?: (validationResponse: MembershipEmailZipcodePayload) => void;
    onFailure?: (validationResponse: MembershipEmailZipcodePayload) => void;
}

export const useEmailZipcodeVerification = () => {
    const { isBusy } = useSelector(membershipRegistrationSelector);

    const dispatch = useDispatch();

    const verifyEmailZipcode = ({ email, zipcode, onSuccess, onFailure }: VerifyEmailZipcodeProps) => {
        if (isBusy) return;
        dispatch(membershipRegistrationGetEmailZipcodeRoutine({ email, zipcode, onSuccess, onFailure }));
    };

    return {
        isBusy,
        verifyEmailZipcode
    };
};
